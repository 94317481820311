import { post } from "../../base/request"
import Config from "../../config/default"

export type SignupProfile = {
  username: string
  password: string
  confirm: string
  invitation?: string
  name?: string
  email?: string
  phone?: string
}

const signup = async (profile: SignupProfile) => {
  return await post<{ message: string }>(
    "/auth/signup",
    {
      ...profile,
    },
    Config.APIServerAuth
  )
}

export const API = { signup }
