import Config from "../../config/default"
import { get, post } from "../request"

export type Session = {
  username: string
  userid: number
  info?: {
    staff?: {
      id: number
      name: string
      role: string
      company?: { id: number; name: string }
    }
  }
}

const signin = async ({
  username,
  password,
}: {
  username: string
  password: string
}) => {
  return await post<{
    token: string
    user: { id: number; username: string }
    staff: {
      id: number
      name: string
      role: string
      company: { id: number; name: string }
    }
  }>(
    "/auth/signin",
    {
      username,
      password,
    },
    Config.APIServerAuth
  )
}

const signout = async ({ username }: { username: string }) => {
  return await post<{ msg: string }>(
    "/auth/signout",
    { username },
    Config.APIServerAuth
  )
}

const verify = async (token: string) => {
  return await post<{ token: string }>("/auth/verify", { token }, Config.APIServerAuth)
}

type StaffInfo = {
  id: number
  name: string
  role: {
    id: number
    name: string
  }
}

export const getStaffInfo = async () => {
  return get<StaffInfo>("/company/staff/info")
}

export const API = { signin, signout, verify }
