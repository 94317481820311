import { Button, Card, Form, Input, message } from "antd"
import { Link, useNavigate } from "react-router-dom"
import { Container } from "../common/Layout"
import { API, SignupProfile } from "./request"
import { Page } from "./page"

export const Signup = ({ redirect }: { redirect?: string }) => {
  const navigate = useNavigate()
  const onFinish = async (values: SignupProfile) => {
    const response = await API.signup(values)
    if (response.message === "success") {
      message.success("注册成功")
      redirect && navigate(redirect)
    } else {
      message.error(response.message)
    }
  }
  return (
    <>
      <Page>
        <Card bordered={false}>
          <Form size="large" onFinish={onFinish}>
            <Form.Item
              hasFeedback
              name="username"
              rules={[
                {
                  required: true,
                  message: "至少4位字母或数字",
                  pattern: /[0-9a-zA-Z@._-]{4,}$/,
                },
              ]}
            >
              <Input placeholder="用户名" autoComplete="off" />
            </Form.Item>
            <Form.Item
              hasFeedback
              name="password"
              rules={[{ required: true, message: "密码不足8位", min: 8 }]}
            >
              <Input.Password placeholder="密码" />
            </Form.Item>
            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "请再次输入密码",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error("与密码不同"))
                  },
                }),
              ]}
            >
              <Input.Password placeholder="确认密码" />
            </Form.Item>
            {/* <Form.Item name="invition" rules={[{ required: false }]}>
              <Input placeholder="公司邀请码（选填）" />
            </Form.Item> */}
            <Form.Item
              name="Name"
              rules={[{ required: true, message: "请输入姓名" }]}
            >
              <Input placeholder="姓名" />
            </Form.Item>
            <Form.Item name="email" rules={[{ required: false }]}>
              <Input placeholder="邮箱" />
            </Form.Item>
            <Form.Item name="phone" rules={[{ required: false }]}>
              <Input placeholder="手机" />
            </Form.Item>
            <Form.Item>
              <Container
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "50%" }}
                >
                  注册
                </Button>
                <Link to="/signin">使用已有账户登录</Link>
              </Container>
            </Form.Item>
          </Form>
        </Card>
      </Page>
    </>
  )
}
