import { Button, Card, Form, Input, message } from "antd"
import { Link, Navigate, useLocation } from "react-router-dom"
import { useAuth } from "../../base/auth/context"
import { Container } from "../common/Layout"
import { Page } from "./page"
import { useProfileContext } from "../module/profile/context"

export const Signin = () => {
  const auth = useAuth()
  const location = useLocation()
  const { state } = useProfileContext()

  const onFinish = async (values: any) => {
    try {
      const res = await auth.signin({
        username: values.username,
        password: values.password,
      })
      if (res.success) {
        message.success("sign in")
      } else {
        message.error(res.message)
      }
    } catch (error) {
      message.error(`${error}`)
    }
  }

  if (state === "authed") {
    const state = location.state as { from: Location } | null
    return <Navigate to={state?.from.pathname ?? "/"} replace></Navigate>
  }

  return (
    <>
      <Page>
        <Card bordered={false}>
          <Form size="large" onFinish={onFinish}>
            <Form.Item style={{ height: "0px" }}></Form.Item>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "请输入用户名" }]}
            >
              <Input placeholder="用户名" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password placeholder="密码" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                登录
              </Button>
            </Form.Item>
            <Form.Item>
              <Container
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Link to="/forget">忘记密码请联系管理员</Link>
                <Link to="/signup">注册账户</Link>
              </Container>
            </Form.Item>
          </Form>
        </Card>
      </Page>
    </>
  )
}
