import { PageContainer, ProLayout } from "@ant-design/pro-components"
import { Button } from "antd"
import { Suspense, useEffect, useState } from "react"
import {
  Link,
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
} from "react-router-dom"
import { useAuth } from "../../base/auth/context"
import { getFile } from "../../base/request"
import Config from "../../config/default"
import { useProfileContext } from "../module/profile/context"

export const SiderPageLayout = ({
  children,
  route,
  selfPath,
}: {
  children?: React.ReactNode
  route?: RouteObject
  selfPath?: string
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { signout } = useAuth()
  const { staff, state } = useProfileContext()
  const [avatarSrc, setAvatarSrc] = useState("")
  const [title, setTitle] = useState("")

  useEffect(() => {
    if (staff?.company?.name) {
      setTitle(staff?.company?.name)
    }
    if (staff?.photo?.[0]?.name) {
      getFile({ url: Config.FileServer + "/" + staff?.photo?.[0]?.name }).then(
        (res) => {
          setAvatarSrc(res)
        }
      )
    }
  }, [staff])

  if (state === "pending") {
    return <>111</>
  } else if (state === "unauthed") {
    return <Navigate to={"/signin"} replace></Navigate>
  }

  return (
    <ProLayout
      title={title}
      logo={<img src="/logo-trans-cloud.png" alt="logo"></img>}
      avatarProps={{
        src: avatarSrc,
        size: "small",
        title: staff?.name,
        render: (props, defaultDom) => (
          // <Link to={selfPath ?? "/self"}>{defaultDom}</Link>
          <div
            onClick={() => {
              navigate(selfPath ?? "/self")
            }}
          >
            {defaultDom}
          </div>
        ),
      }}
      pageTitleRender={false}
      layout="side"
      siderMenuType="sub"
      token={{ sider: { colorMenuBackground: "#fff" } }}
      siderWidth={164}
      // collapsed={true}
      // collapsedButtonRender={false}
      breadcrumbRender={false}
      menu={{
        defaultOpenAll: false,
        hideMenuWhenCollapsed: false,
      }}
      location={{ pathname: location.pathname }}
      onMenuHeaderClick={(e) => console.log(e)}
      menuItemRender={(item, dom) => <Link to={item.path ?? ""}>{dom}</Link>}
      actionsRender={(props) => {
        return [
          <Button
            type="text"
            size="small"
            onClick={() => {
              signout(() => {
                navigate("/signin")
              })
            }}
          >
            退出
          </Button>,
        ]
      }}
      route={
        route ?? {
          path: "/",
          routes: [
            {
              path: "/welcome",
              name: "欢迎",
            },
          ],
        }
      }
    >
      {children ? children : <Outlet />}
    </ProLayout>
  )
}
export const SiderPage = ({
  route,
  selfPath,
}: {
  route?: RouteObject
  selfPath?: string
}) => {
  return (
    <SiderPageLayout route={route} selfPath={selfPath}>
      <PageContainer>
        <Suspense fallback={<div>loading...</div>}>
          <Outlet />
        </Suspense>
      </PageContainer>
    </SiderPageLayout>
  )
}

export default SiderPage
