type Header = {
  alg: string
  typ: string
}

type Payload = {
  sub: string
  id: number
  username: string
  exp: number
  iss?: string
  scop?: string
}

export type JWT = {
  header: Header
  payload: Payload
  signature: string
}

export const JWTParse = (token: string) => {
  const [header, payload, signature] = token.split(".")
  return {
    header: decode<Header>(header),
    payload: decode<Payload>(payload),
    signature,
  }
}

const decode = <T = any>(base64Url: string) => {
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64Url.replace(/-/g, "+").replace(/_/g, "/"))
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  )
  return JSON.parse(jsonPayload) as T
}
