import styles from "./Layout.module.css"

type LayoutProps = {
  children?: React.ReactNode
  width?: string
  height?: string
  style?: React.CSSProperties
}

export const Container = ({
  children,
  width = "100%",
  height = "100%",
  style,
}: LayoutProps) => {
  return (
    <div
      className={styles.container}
      style={{
        width: width,
        height: height,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export const Block = ({
  children,
  width = "auto",
  height = "auto",
  style,
}: LayoutProps) => {
  return (
    <div
      className={styles.block}
      style={{
        width: width,
        height: height,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
