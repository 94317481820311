// const server = "http://localhost:8080"

import path from "../../common/util/path"

// const server = "https://81.68.193.17"
const server = (process.env.NODE_ENV === "production" ? "https://joyyun.com/api" : "http://localhost:4005")

console.log(server)
console.log(process.env.NODE_ENV)

const Config = {
  APIServerAuth:
    process.env.REACT_APP_APIServerAuth ?? server + "/v1",
  APIServer: process.env.REACT_APP_APIServer ?? server + "/v1/0",
  FileServer:
    path(process.env.REACT_APP_FileServer ?? server + "/v1/0/file"),
  LoggerMethod: process.env.REACT_APP_LoggerMethod ?? "console",
}

export default Config
