import Cookies from "js-cookie"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { Navigate, useLocation } from "react-router-dom"
import { API, Session } from "./api"
import { JWTParse } from "./jwt"

type Credential = {
  username: string
  password: string
}

type AuthStatus = "loading" | "authenticated" | "unauthenticated"

const AuthContext = createContext<{
  status: AuthStatus
  session: Session | null
  signin: (
    credential?: Credential
  ) => Promise<{ success: boolean; message: string }>
  signout: (callback: () => void) => void
}>(null!)

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [status, setStatus] = useState<AuthStatus>("loading")
  // session is not used in app
  const [session, setSession] = useState<Session | null>(null)

  const signin = useCallback(async (credential?: Credential) => {
    setStatus("loading")
    try {
      if (credential) {
        const response = await API.signin({ ...credential })
        if (response.ok && response.data) {
          Cookies.set("token", response.data.token, { expires: 7 })
          const jwt = JWTParse(response.data.token)
          const freshSession = {
            userid: jwt.payload.id,
            username: jwt.payload.username,
            info: { staff: response.data.staff },
          }
          setSession(freshSession)
          setStatus("authenticated")
          return { success: true, message: "" }
        } else {
          setStatus("unauthenticated")
          return {
            success: false,
            message: `bad response: ${response.message}`,
          }
        }
      } else {
        const token = Cookies.get("token")
        if (!token) return { success: false, message: "no credential" }
        const jwt = JWTParse(token)
        const freshSession = {
          userid: jwt.payload.id,
          username: jwt.payload.username,
        }
        setSession(freshSession)
        setStatus("authenticated")
        return { success: true, message: "" }
      }
    } catch (error) {
      console.log(error)
      setStatus("unauthenticated")
      return { success: false, message: `error: ${error}` }
    }
  }, [])

  const signout = useCallback((callback: () => void) => {
    setStatus("loading")
    setSession(null)
    Cookies.remove("token")
    setStatus("unauthenticated")
    callback()
  }, [])

  useEffect(() => {
    const token = Cookies.get("token")
    if (token) {
      API.verify(token).then((res) => {
        if (res.message === "success") {
          setStatus("authenticated")
        } else {
          setStatus("unauthenticated")
        }
      })
    } else {
      setStatus("unauthenticated")
    }
  }, [])

  return (
    <>
      <AuthContext.Provider value={{ status, session, signin, signout }}>
        {children}
      </AuthContext.Provider>
    </>
  )
}

export const AuthCheck = ({ children }: { children: ReactNode }) => {
  const auth = useAuth()
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (auth.status === "unauthenticated") {
      auth.signin().then((v) => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [auth])

  if (auth.session) {
    return <>{children}</>
  }

  if (auth.status === "loading" || loading) {
    return <>loading</>
  }

  return <Navigate to="/signin" state={{ from: location }} replace />
}
