class Path {
  private path: string
  constructor(path: string | number) {
    if (path === "") {
      this.path = "/"
    } else {
      this.path = path.toString()
    }
  }

  public join(
    ...paths: (Path | string | number | undefined | null | boolean)[]
  ) {
    let result =
      this.path.slice(-1) === "/" ? this.path.slice(0, -1) : this.path
    for (let i = 0; i < paths.length; i++) {
      const element = paths[i]
      if (
        typeof element === "string" ||
        typeof element === "number" ||
        element instanceof Path
      ) {
        let sub = element.toString()

        if (sub[0] !== "/") {
          sub = "/" + sub
        }
        if (sub[sub.length - 1] === "/") {
          sub = sub.slice(0, -1)
        }
        if (sub === "") {
          continue
        }
        result += sub
      }
      return result
    }
  }

  public toString() {
    return this.path
  }
}

const path = (_: string | number) => new Path(_)

export default path
