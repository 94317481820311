import { MenuDataItem } from "@ant-design/pro-components"
import { lazy } from "react"
import { type Permission } from "../pages/company/Staff/Permission/api"
import { type Staff } from "../pages/company/type"

const ActivityCreate = lazy(
  () => import("../app/ui/module/activity/ActivityCreate")
)
const ActivityDetail = lazy(
  () => import("../app/ui/module/activity/ActivityDetail")
)
const ActivityList = lazy(
  () => import("../app/ui/module/activity/ActivityList")
)
const ChannelCreate = lazy(
  () => import("../app/ui/module/channel/ChannelCreate")
)
const ChannelList = lazy(() => import("../app/ui/module/channel/ChannelList"))
const CompanySetting = lazy(() => import("../pages/company/Setting"))
const CompanyStaff = lazy(() => import("../pages/company/Staff/CompanyStaff"))
const CompanyZone = lazy(() => import("../pages/company/zone/Zone"))
const CustomerAll = lazy(() => import("../app/ui/module/customer/CustomerAll"))
const CustomerCreate = lazy(
  () => import("../app/ui/module/customer/CustomerCreate")
)
const CustomerDetail = lazy(
  () => import("../app/ui/module/customer/CustomerDetail")
)
const CustomerPrivate = lazy(
  () => import("../app/ui/module/customer/CustomerPrivate")
)
const CustomerPublic = lazy(
  () => import("../app/ui/module/customer/CustomerPublic")
)
const CustomerTag = lazy(
  () => import("../app/ui/module/customer/CustomerTag/CustomerTag")
)
const Dashboard = lazy(() => import("../app/ui/module/dashboard"))
const MailAccountList = lazy(() => import("../app/ui/module/mail/MailAccount"))
const MailBox = lazy(() => import("../app/ui/module/mail/MailBox"))
const MailMessage = lazy(() => import("../app/ui/module/mail/MailMessage"))
const MailSend = lazy(() => import("../app/ui/module/mail/MailSend"))
const OrderCreate = lazy(
  () => import("../app/ui/module/order/component/OrderCreate")
)
const OrderDetail = lazy(
  () => import("../app/ui/module/order/component/OrderDetail")
)
const OrderAll = lazy(
  () => import("../app/ui/module/order/component/OrderListAll")
)
const OrderPrivate = lazy(
  () => import("../app/ui/module/order/component/OrderListPrivate")
)
const ProductAll = lazy(() => import("../app/ui/module/product/ProductAll"))
const ProductCreate = lazy(
  () => import("../app/ui/module/product/ProductCreate")
)
const ProductDetail = lazy(
  () => import("../app/ui/module/product/ProductDetail")
)
const SelfInfo = lazy(() => import("../app/ui/module/self/Info"))
const CompanyMail = lazy(() => import("../pages/company/Mail"))
const FollowupStat = lazy(() => import("../app/ui/module/customer/Followup"))
const FollowupMine = lazy(
  () => import("../app/ui/module/customer/FollowupMine")
)
const StaffStat = lazy(() => import("../app/ui/module/stat/StaffStat"))
const StaffTagStat = lazy(() => import("../pages/stat/StaffTagStat"))
const ProductStat = lazy(() => import("../app/ui/module/stat/ProductStat"))
const SupplierList = lazy(
  () => import("../app/ui/module/product/supplier/SupplierList")
)
const ForwarderList = lazy(
  () => import("../app/ui/module/product/forwarder/ForwarderList")
)

// const ActivityBoard = lazy(() => import("../app/ui/module/activity/Board"))

// only for test
// const TestArea = lazy(() => import("../pages/TestArea"))

type Route = Omit<MenuDataItem, "routes"> & {
  children?: Route[]
  permitted?: boolean
}

type RouteBuilder = (permission?: Permission, staff?: Staff) => Route

const homeRoute: RouteBuilder = () => {
  return {
    path: "",
    name: <span>首页</span>,
    element: <Dashboard />,
  }
}

const dashboardRoute: RouteBuilder = () => {
  return {
    path: "dashboard",
    name: "首页",
    element: <Dashboard />,
  }
}

const customerRoute: RouteBuilder = () => {
  return {
    path: "customer",
    name: "客户",
    icon: <span>客户</span>,
    children: [
      {
        path: "create",
        name: "添加客户",
        element: <CustomerCreate />,
      },
      {
        path: "public",
        name: "公海",
        element: <CustomerPublic />,
      },
      {
        path: "private",
        name: "鱼塘",
        element: <CustomerPrivate />,
      },
      {
        path: "all",
        name: "全部客户",
        element: <CustomerAll />,
        // permitted: permission?.customer?.read?.all === true,
      },
      {
        path: "followup",
        name: "跟进",
        element: <FollowupMine />,
      },
      {
        path: "followupStat",
        name: "跟进统计",
        element: <FollowupStat />,
        // permitted: permission?.followup?.read?.all === true,
      },
      {
        path: "detail/:sn",
        element: <CustomerDetail />,
      },
      {
        path: "detail",
        element: <CustomerDetail />,
      },
    ],
  }
}

const productRoute: RouteBuilder = () => {
  return {
    path: "product",
    name: "产品",
    children: [
      {
        path: "create",
        name: "添加产品",
        element: <ProductCreate />,
      },
      {
        path: "all",
        name: "产品列表",
        element: <ProductAll />,
      },
      {
        path: "supplier",
        name: "供应商",
        element: <SupplierList />,
      },
      {
        path: "forwarder",
        name: "货代",
        element: <ForwarderList />,
      },
      {
        path: "detail/:sn",
        element: <ProductDetail />,
      },
    ],
  }
}
const orderRoute: RouteBuilder = (permission?: Permission) => {
  return {
    path: "order",
    name: "订单",
    children: [
      {
        path: "create",
        name: "添加订单",
        element: <OrderCreate></OrderCreate>,
      },
      {
        path: "private",
        name: "我的订单",
        element: <OrderPrivate></OrderPrivate>,
      },
      {
        path: "all",
        name: "全部订单",
        element: <OrderAll></OrderAll>,
        permitted: permission?.order?.read?.all === true,
      },
      {
        path: "detail/:sn",
        element: <OrderDetail></OrderDetail>,
      },
    ],
  }
}

const channelRoute: RouteBuilder = () => {
  return {
    path: "channel",
    name: "渠道",
    children: [
      {
        path: "create",
        name: "添加渠道",
        element: <ChannelCreate />,
      },
      {
        path: "all",
        name: "渠道列表",
        element: <ChannelList />,
      },
      // {
      //   path: "detail/:sn",
      //   element: <ProductDetail />,
      // },
    ],
  }
}
const activityRoute: RouteBuilder = (permission?: Permission) => {
  return {
    path: "activity",
    name: "活动",
    permitted: permission?.activity?.read?.all === true,
    children: [
      {
        path: "create",
        name: "添加活动",
        element: <ActivityCreate />,
      },
      {
        path: "all",
        name: "活动列表",
        element: <ActivityList />,
      },
      {
        path: "detail/:sn",
        element: <ActivityDetail />,
      },
      // {
      //   path: "board/:sn",
      //   element: <ActivityBoard />,
      // },
    ],
  }
}

const mailRoute: RouteBuilder = () => {
  return {
    path: "mail",
    name: "邮件",
    children: [
      {
        path: "account",
        name: "邮箱账号",
        element: <MailAccountList personal />,
      },
      {
        path: "send",
        name: "发送邮件",
        element: <MailSend />,
      },
      {
        path: "box",
        name: "收件箱",
        element: <MailBox />,
      },
      {
        path: "message/:address/:mailbox/:uid",
        element: <MailMessage />,
      },
    ],
  }
}

const settingRoute: RouteBuilder = (permission?: Permission, staff?: Staff) => {
  return {
    path: "setting",
    name: "公司设置",
    permitted:
      permission?.setting?.read?.all === true || staff?.initialRole === "ADMIN",
    children: [
      {
        path: "setting",
        name: "设置",
        element: <CompanySetting />,
      },
      {
        path: "staff",
        name: "成员权限",
        element: <CompanyStaff />,
      },
      {
        path: "zone",
        name: "公海",
        element: <CompanyZone />,
      },
      {
        path: "customerTag",
        name: "客户标签",
        element: <CustomerTag />,
      },
      {
        path: "productCategory",
        name: "产品类目",
      },
      {
        path: "mail",
        name: "邮件设置",
        element: <CompanyMail />,
      },
    ],
  }
}

const statRoute: RouteBuilder = (permission?: Permission) => {
  return {
    path: "stat",
    name: "统计",
    permitted: permission?.stat?.read?.all === true,
    children: [
      {
        path: "staff",
        name: "人员",
        element: <StaffStat />,
      },
      {
        path: "tag",
        name: "标签",
        element: <StaffTagStat />,
      },
      {
        path: "product",
        name: "产品",
        element: <ProductStat />,
      },
      {
        path: "channel",
        name: "渠道",
        element: <StaffStat />,
      },
    ],
  }
}

const selfRoute: RouteBuilder = () => {
  return {
    path: "self",
    element: <SelfInfo></SelfInfo>,
  }
}

export const allRoutes = (permission?: Permission, staff?: Staff) => [
  homeRoute(permission, staff),
  dashboardRoute(permission, staff),
  customerRoute(permission, staff),
  productRoute(permission, staff),
  orderRoute(permission, staff),
  channelRoute(permission, staff),
  activityRoute(permission, staff),
  mailRoute(permission, staff),
  settingRoute(permission, staff),
  statRoute(permission, staff),
  selfRoute(permission, staff),
]
