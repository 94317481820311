import { Block, Container } from "../common/Layout"
import { Children } from "../common/type"

export const Page = ({ children }: Children) => {
  return (
    <>
      <Container
        style={{
          height: "80vh",
          backgroundImage: "url(/logo-trans-cloud.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Block
          style={{
            marginTop: "96px",
            width: "420px",
          }}
        >
          {children}
        </Block>
      </Container>
    </>
  )
}
