import Cookies from "js-cookie"

const defaultTimeout = 20000

class FetchXConfig {
  timeout: number
  authorization: string
  server: string | null
  constructor(init?: { timeout?: number; token?: string; server?: string }) {
    this.timeout = init?.timeout || defaultTimeout
    const token = init?.token || Cookies.get("token")
    this.authorization = `Bearer ${token}`
    this.server = init?.server ?? null
  }

  setTimeout(timeout?: number) {
    this.timeout = timeout || defaultTimeout
  }

  setBaseUrl(server: string) {
    this.server = server
  }

  setAuthorization(token: string) {
    this.authorization = `Bearer ${token || Cookies.get("token")}`
  }
}

export const fetchXConfig = new FetchXConfig()
