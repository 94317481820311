import Cookies from "js-cookie"
import { useCallback, useEffect, useState } from "react"
import Config from "../../config/default"

const apiServer = Config.APIServer
const fileServer = Config.FileServer

export type RequestResponse<DataType = any> = {
  ok: boolean
  data?: DataType
  message?: string
}

type Request = <DataType = null>(
  api: string,
  args?: any,
  server?: string
) => Promise<{ ok: boolean; data?: DataType; message?: string }>

export const fetchX = (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  timeout = 20000
) => {
  const controller = new AbortController()
  const signal = controller.signal
  setTimeout(() => {
    controller.abort()
  }, timeout)
  return fetch(input, {
    ...init,
    signal,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
}

export const get: Request = async (
  api: string,
  args?: any,
  server: string = apiServer
) => {
  let fetchURL = `${server}${api}`
  if (args) {
    const query = new URLSearchParams()
    for (const key in args) {
      const value = args[key]
      if (!value) continue
      if (Array.isArray(value)) {
        value.forEach((v) => {
          query.append(key, v)
        })
      } else {
        query.append(key, value)
      }
    }
    fetchURL = `${server}${api}?${query.toString()}`
  }
  try {
    console.log("request get " + api)
    const res = await fetchX(fetchURL, { credentials: "include" })
    const json = await res.json()
    if (res.ok) {
      return { ok: true, ...json }
    } else {
      return {
        ok: false,
        message: `return code: ${res.status} | return message: ${json.message}`,
      }
    }
  } catch (error) {
    return { ok: false, message: `error: ${error}` }
  }
}

export const post: Request = async (
  api: string,
  args: any,
  server: string = apiServer
) => {
  try {
    console.log("request post " + api)
    const res = await fetchX(`${server}${api}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(args),
      credentials: "include",
    })
    const json = await res.json()
    if (res.ok) {
      return { ok: true, ...json }
    } else {
      return {
        ok: false,
        message: `return code: ${res.status} | return message: ${json.message}`,
      }
    }
  } catch (error) {
    return { ok: false, message: `error: ${error}` }
  }
}

export const getFile = async ({
  url,
  filename,
  server = fileServer.toString(),
  method = "GET",
}: {
  url?: string
  filename?: string
  server?: string
  method?: "GET" | "POST"
}) => {
  try {
    if (!url) {
      url = `${server}/${filename}`
    }
    const res = await fetchX(url, {
      method: method,
      credentials: "include",
    })
    if (res.ok) {
      const blob = await res.blob()
      console.log(blob)
      return URL.createObjectURL(blob)
    } else {
      console.error(res.status, res.statusText)
      return ""
    }
  } catch (error) {
    console.error(error)
    return ""
  }
}

export const download = async (
  api: string,
  args: any,
  server: string = apiServer
) => {
  try {
    const res = await fetchX(`${server}${api}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(args),
      credentials: "include",
    })
    if (res.ok) {
      console.log(res.headers.get("Content-Encoding"))
      const text = await res.text()
      const b = window.atob(text)
      const u8Array = new Uint8Array(b.length)
      for (let i = 0; i < b.length; i++) {
        u8Array[i] = b.charCodeAt(i)
      }
      const blob = new Blob([u8Array])
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = args.name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } else {
      console.error(res.status, res.statusText)
    }
  } catch (error) {
    console.error(error)
  }
}

export const upload = async <T = { id: number; name: string }>(
  api: string,
  fileList: File[] | File
) => {
  try {
    const formData = new FormData()
    if (Array.isArray(fileList)) {
      fileList.forEach((file) => {
        formData.append("files[]", file)
      })
    } else {
      formData.set("file", fileList)
    }

    const res = await fetchX(`${apiServer}${api}`, {
      credentials: "include",
      method: "POST",
      body: formData,
    })
    console.log(res)
    const json = (await res.json()) as { message: string; data: T }
    if (res.ok) {
      return { ok: true, ...json }
    } else {
      return {
        ok: false,
        message: `return code: ${res.status} | return message: ${json.message}`,
      }
    }
  } catch (error) {
    console.log(error)
    return { ok: false, message: `error: ${error}` }
  }
}

const useFetch = <DataType = any>(api: string, options?: RequestInit) => {
  const [data, setData] = useState<DataType>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>()
  const request = () => {
    fetchX(api, { ...options, mode: "cors", credentials: "include" })
      .then((res) => res.json())
      .then((res) => {
        setData(res.data)
        setError(undefined)
        setLoading(false)
      })
      .catch((reason) => {
        setData(undefined)
        setError(reason)
        setLoading(false)
      })
  }
  const refresh = (newData?: DataType) => {
    if (newData) setData(newData)
    else request()
  }
  useEffect(() => {
    request()
    // eslint-disable-next-line
  }, [api, options?.body])

  return { data, loading, error, refresh }
}

export const useGet = <DataType = any>(api: string, args?: any) => {
  let fetchURL = `${apiServer}${api}`
  if (args) {
    const query = new URLSearchParams()
    for (const key in args) {
      const value = args[key]
      if (!value) continue
      if (Array.isArray(value)) {
        value.forEach((v) => {
          query.append(key, v)
        })
      } else {
        query.append(key, value)
      }
    }
    fetchURL = `${apiServer}${api}?${query.toString()}`
  }
  return useFetch<DataType>(fetchURL)
}

export const usePost = <DataType = any>(api: string, args: any) => {
  return useFetch<DataType>(`${apiServer}${api}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(args),
  })
}

export const cache = <T>(
  key: string,
  options: { sec?: number; location?: "localStorage" | "memory" } = {},
  request: () => Promise<T>
) => {
  const { sec = 1000, location = "memory" } = options

  key = key || request.name + Math.random()

  if (location === "localStorage") {
    const f = async (params?: { refresh?: boolean }) => {
      const now = Date.now()
      const localData = JSON.parse(localStorage.getItem(key) ?? "null")
      if (
        !params?.refresh &&
        localData &&
        now - (localData.timestamp ?? 0) < sec * 1000
      ) {
        console.log("cached------------")
        return localData.data as T
      }
      console.log("fresh-------------")
      const res = await request()
      localStorage.setItem(
        key,
        JSON.stringify({ data: res, timestamp: Date.now() })
      )
      return res
    }
    return f
  }

  let data: T
  let lastFreshTime = 0
  const f = async (params?: { refresh?: boolean }) => {
    const now = Date.now()
    if (!params?.refresh && data && now - lastFreshTime < sec * 1000) {
      return data
    }
    const res = await request()
    lastFreshTime = now
    data = res
    return data
  }
  return f
}

export const usePoll = <T>(
  api: string,
  args: any,
  interval: number = 1000,
  timeout: number = 2000,
  server: string = apiServer
) => {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>()

  const request = useCallback(() => {
    setLoading(true)
    const fetchURL = `${server}${api}`
    fetchX(
      fetchURL,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(args),
        mode: "cors",
        credentials: "include",
      },
      timeout
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res.data)
        setError(undefined)
        setLoading(false)
      })
      .catch((reason) => {
        setData(undefined)
        setError(reason)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refresh = useCallback(
    (newData?: T) => {
      if (newData) setData(newData)
      else request()
    },
    [request]
  )

  useEffect(() => {
    const id = window.setInterval(request, interval)
    request()
    console.log({ id })
    return () => {
      window.clearInterval(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request])

  return { data, loading, error, refresh }
}
