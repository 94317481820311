import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"
import { get } from "../../../base/request"
import type { Permission, Role } from "../../../../pages/company/Staff/Permission/api"
import type { Company, Staff } from "../../../../pages/company/type"
import { useAuth } from "../../../base/auth/context"

type AuthState = "pending" | "authed" | "unauthed"

const ProfileContext = createContext<{
  staff?: Staff
  company?: Company
  permission?: Permission
  role?: Role
  state?: AuthState
}>({})

export const useProfileContext = () => {
  return useContext(ProfileContext)
}

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<AuthState>("pending")
  const [staff, setStaff] = useState<Staff>()
  const [company, setCompany] = useState<Company>()
  const [role, setRole] = useState<Role>()
  const [permission, setPermission] = useState<Permission>()
  const { status } = useAuth()

  useEffect(() => {
    if (status === "authenticated") {
      get<Staff>("/company/staff/self")
        .then((res) => {
          if (res.data) {
            const staff = res.data
            setStaff(staff)
            setCompany(staff?.company)
            setRole(staff?.role)
            setPermission(
              staff?.role?.permission ?? { Default: staff?.initialRole }
            )
            console.log(staff?.role?.permission, staff)
          } else {
            setState("unauthed")
          }
        })
        .catch((err) => {
          console.log(err)
          setState("unauthed")
        })
    }
    if (status === "unauthenticated") {
      setState("unauthed")
    }
  }, [status])

  useEffect(() => {
    if (permission) {
      setState("authed")
    }
    console.log({ permission })
  }, [permission])

  return (
    <ProfileContext.Provider
      value={{ staff, company, role, permission, state }}
    >
      {state !== "pending" && children}
    </ProfileContext.Provider>
  )
}
