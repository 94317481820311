import { MenuDataItem } from "@ant-design/pro-components"
import { lazy, useEffect, useMemo } from "react"
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom"
import { AuthProvider } from "../app/base/auth/context"
import Config from "../app/config/default"
import {
  ProfileProvider,
  useProfileContext,
} from "../app/ui/module/profile/context"
import SiderPage from "../app/ui/page/SiderPage"
import { Signin, Signup } from "../app/ui/sign"
import { fetchXConfig } from "../common/fetchx/init"
import { DefaultRole } from "../pages/company/Staff/Permission/api"
import { allRoutes } from "./routes"
console.log(Config)
fetchXConfig.setBaseUrl(Config.APIServer)

const ActivityBoard = lazy(() => import("../app/ui/module/activity/Board"))
const Welcome = lazy(() => import("../app/ui/sign/Welcome"))

type Route = Omit<MenuDataItem, "routes"> & {
  children?: Route[]
  permitted?: boolean
}
function getAntdRoute(route: (RouteObject & { name?: string })[]): Route {
  return route
    .map((e) => {
      if (e.name) {
        if (e.children) {
          const children = getAntdRoute(e.children)
          return { name: e.name, path: e.path, children: children }
        } else {
          return { name: e.name, path: e.path }
        }
      }
      return undefined
    })
    .filter((e) => e !== undefined)
}

function getpermittedRoutes(
  routes: Route[],
  defaultRole?: DefaultRole
): Route[] {
  const permittedRoutes: Route[] = []
  for (const route of routes) {
    if (defaultRole !== "ADMIN" && route.permitted === false) {
      continue
    }
    if (route.children) {
      route.children = getpermittedRoutes(route.children)
    }
    permittedRoutes.push(route)
  }
  return permittedRoutes
}

const Router = () => {
  const { permission, staff } = useProfileContext()

  useEffect(() => {
    console.log(permission)
  }, [permission])

  const appRoutes = useMemo(() => {
    return getpermittedRoutes(allRoutes(permission, staff), permission?.Default)
  }, [permission, staff])

  const appMenu = useMemo(
    () => ({
      path: "/",
      routes: getAntdRoute(appRoutes),
    }),
    [appRoutes]
  )

  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: "signin",
          element: <Signin></Signin>,
        },
        {
          path: "signup",
          element: <Signup></Signup>,
        },
        {
          path: "welcome",
          element: <Welcome></Welcome>,
        },
        {
          path: "/",
          element: <SiderPage route={appMenu} selfPath={"/self"}></SiderPage>,
          children: appRoutes,
        },
        {
          path: "activity/board/:sn",
          element: <ActivityBoard></ActivityBoard>,
        },
      ])}
    />
  )
}

export const App = () => {
  return (
    // <ProConfigProvider>
    <AuthProvider>
      <ProfileProvider>
        <Router></Router>
      </ProfileProvider>
    </AuthProvider>
    // </ProConfigProvider>
  )
}
